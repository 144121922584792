import * as React from "react"
import { gsap } from "../plugins/gsap"
import { TextPlugin } from "../plugins/gsap/TextPlugin"

gsap.registerPlugin(TextPlugin)

const AnimatedText = ({ sentence }) => {
  React.useEffect(() => {
    gsap.to("#animCursor", { opacity: 0, ease: "power2.inOut", repeat: -1 })
    gsap.to("#animText", { duration: 0.8, text: sentence })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <React.Fragment>
      <span className="bg-clip-text inline-block text-transparent bg-gradient-to-r from-blue to-blue-pacific">
        <span id="animText"></span>
        <span
          id="animCursor"
          className="font-normal inline-block text-blue-pacific">
          _
        </span>
      </span>
    </React.Fragment>
  )
}

export default AnimatedText
