import React, { useEffect, useRef } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { gsap } from "../plugins/gsap"
import { ScrollTrigger } from "../plugins/gsap/ScrollTrigger"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { Container, Row, Col } from "../components/ui/grid"
import Layout from "../components/layout"
import Seo from "../components/seo"
import HeroHome from "../components/hero-home"
import Feature from "../components/ui/feature"
import CtaEnterprise from "../components/cta-enterprise"
import CardFeature from "../components/ui/card-feature"
import SupportStack from "../components/support-stack"
import ShapeDown from "../components/ui/shape-down"
import ShapeLight from "../svg/shape-light.svg"
import LogoEl2 from "../svg/logo-el-2.svg"
import LogoEl3 from "../svg/logo-el-3.svg"
import TextGradient from "../components/ui/text-gradient"
import Button from "../components/ui/button"
import { EditableYaml, getYamlContent } from "../components/editableYaml"

gsap.registerPlugin(ScrollTrigger)

const options = {
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      const type = node?.data?.target?.__typename || null

      if (type !== "ContentfulTextGradient") {
        return children
      }

      return <TextGradient>{node?.data?.target?.content}</TextGradient>
    },
    [BLOCKS.HEADING_2]: (node, children) => {
      return <h2 className="text-white xl:text-5xl">{children}</h2>
    },
    [BLOCKS.PARAGRAPH]: (node, children) => {
      return (
        <p className="mt-6 font-medium text-lg text-gray-light">{children}</p>
      )
    },
  },
}

const Index = ({ location }) => {
  const { data } = useStaticQuery(
    graphql`
      query {
        data: contentfulPageHome {
          title
          description
          featuredImage {
            gatsbyImageData
            resize(width: 512, quality: 70) {
              src
            }
          }
          chromeExtentionImage {
            gatsbyImageData
            resize(width: 512, quality: 70) {
              src
            }
          }
          features {
            title
            id
            content {
              raw
            }
            image {
              gatsbyImageData(
                layout: FULL_WIDTH
                quality: 70
                placeholder: BLURRED
              )
            }
          }
          featureSpotlight {
            raw
            references {
              ... on ContentfulTextGradient {
                contentful_id
                __typename
                content
              }
            }
          }
          editableTextYaml {
            raw
          }
          gettingStartedTitle {
            raw
            references {
              ... on ContentfulTextGradient {
                contentful_id
                __typename
                content
              }
            }
          }
          gettingStarted {
            id
            title
            description {
              description
            }
            icon {
              file {
                url
              }
            }
          }
          gettingStartedButton {
            title
            url
          }
        }
      }
    `
  )

  const title = data?.title || ""
  const description = data?.description || ""
  const thumbnail = data?.featuredImage?.resize?.src || ""
  const twtrImage = data?.chromeExtentionImage?.resize?.src || ""
  const features = data?.features || []
  const gettingStarted = data?.gettingStarted || []
  const content = data?.featureSpotlight || null
  const gettingStartedTitle = data?.gettingStartedTitle || null
  const gettingStartedButton = data?.gettingStartedButton || null
  const yamlContent = getYamlContent(data?.editableTextYaml || null) || '';
  const sectionRefs = useRef([])
  sectionRefs.current = []
  useEffect(() => {
    sectionRefs.current.forEach((el, index) => {
      gsap.fromTo(
        el,
        {
          autoAlpha: 0,
        },
        {
          duration: 1,
          autoAlpha: 1,
          ease: "power4.out",
          scrollTrigger: {
            id: `section-${index + 1}`,
            trigger: el,
            start: "top 80%",
            toggleActions: "play none none reverse",
          },
        }
      )
    })
  }, [])

  const addToRef = el => {
    if (el && !sectionRefs.current.includes(el)) {
      sectionRefs.current.push(el)
    }
  }

  return (
    <Layout
      location={location}
      hero={<HeroHome />}
      beforeFooter={<CtaEnterprise />}>
      <Seo
        title={title}
        description={description}
        image={thumbnail}
        alterImage={twtrImage}
        url={location.href}
      />

      {features &&
        features.map((item, index) => (
          <Feature
            elRef={addToRef}
            id={item?.id}
            key={item?.id}
            content={item?.content}
            img={item?.image?.gatsbyImageData}
            imgAlt={item?.title}
            imgSide={index % 2 === 0 ? "top-right" : "top-left"}
            alignY="center"
            options="alt"
            className={
              index === features.length - 1
                ? "py-12 lg:py-24 bg-gray-light"
                : "py-12 lg:py-24"
            }>
            {index === features.length - 1 && (
              <ShapeLight className="absolute left-0 top-0 -z-1 w-full h-auto" />
            )}
          </Feature>
        ))}

      <ShapeDown>
        <section
          ref={addToRef}
          className="relative z-10 py-14 lg:pt-8 lg:pb-48">
          <Container>
            <Row alignY="center">
              <Col size="w-full lg:w-2/5">
                <div className="lg:pr-8 xl:pr-14">
                  {content && renderRichText(content, options)}
                </div>
              </Col>

              <Col size="w-full lg:w-3/5">
                <EditableYaml content={yamlContent}/>
              </Col>
            </Row>
          </Container>
        </section>

        <section ref={addToRef}>
          <Container>
            <div className="mx-auto md:mb-10 md:max-w-md lg:max-w-lg lg:mb-16 md:text-center">
              {gettingStartedTitle &&
                renderRichText(gettingStartedTitle, options)}
            </div>

            <Row>
              {gettingStarted &&
                gettingStarted.map(card => (
                  <Col
                    key={card?.id}
                    size="w-full md:w-1/2 xl:w-1/4"
                    className="my-2">
                    <CardFeature
                      icon={card?.icon?.file?.url}
                      title={card?.title}
                      description={card?.description?.description}
                    />
                  </Col>
                ))}
            </Row>

            <div className="mt-8 text-center">
              <Button
                label={gettingStartedButton?.title}
                to={gettingStartedButton?.url}
              />
            </div>
          </Container>
        </section>

        <LogoEl2 className="absolute left-0 -top-12 w-20 h-auto mt-[-5.4rem] lg:mt-[-11.3rem] md:mt-[-10.34rem] lg:w-28 xl:mt-[-11.3rem] 2xl:w-44 2xl:mt-[-14rem]" />
        <LogoEl3 className="absolute left-0 top-0 w-10 h-auto mt-[460px] lg:mt-[490px] lg:w-12 xl:mt-[550px] 2xl:w-32" />
      </ShapeDown>

      <SupportStack elRef={addToRef} />
    </Layout>
  )
}

export default Index
