import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"

import { Container, Row, Col } from "./ui/grid"
import ShapeDown from "./ui/shape-down"
import Customers from "./customers"
import Button from "./ui/button"
import AnimatedText from "./animated-text"
import TextGradient from "./ui/text-gradient"

import poster from "../images/loadmill.png"
import video from "../images/loadmill.mp4"

const options = {
  renderNode: {
    [INLINES.EMBEDDED_ENTRY]: (node, children) => {
      const type = node?.data?.target?.__typename || null

      if (type !== "ContentfulTextGradient") {
        return children
      }

      return <TextGradient>{node?.data?.target?.content}</TextGradient>
    },
    [BLOCKS.HEADING_1]: (node, children) => {
      return (
        <h1 className="text-white pr-12 lg:pr-20 xl:text-6xl">{children}</h1>
      )
    },
  },
}

const HeroHome = () => {
  const { data } = useStaticQuery(
    graphql`
      query {
        data: contentfulPageHome {
          heroAnimatedParagraph
          usersLogosTitle
          heroContent {
            raw
            references {
              ... on ContentfulTextGradient {
                contentful_id
                __typename
                content
              }
            }
          }
          buttonPrimary {
            title
            url
          }
          buttonSecondary {
            title
            url
          }
        }
      }
    `
  )

  const content = data?.heroContent || ""
  const sentence = data?.heroAnimatedParagraph || ""
  const usersLogosTitle = data?.usersLogosTitle || ""
  const btnPrimary = data?.buttonPrimary || ""
  const btnSecondary = data?.buttonSecondary || ""

  return (
    <ShapeDown className="pt-12 lg:pt-40">
      <section className="mb-12 overflow-hidden lg:mb-24">
        <Container>
          <Row>
            <Col size="w-full lg:w-1/2">
              {content && renderRichText(content, options)}

              <p className="mt-6 text-2xl">
                {sentence && <AnimatedText sentence={sentence} />}
              </p>

              <div className="mt-8 space-y-4 md:space-y-0 md:space-x-4">
                <Button
                  label={btnPrimary?.title}
                  to={btnPrimary?.url}
                  color="outlined-blue"
                  className="block md:inline-block"
                />

                <Button
                  label={btnSecondary?.title}
                  to={btnSecondary?.url}
                  className="block md:inline-block"
                />
              </div>
            </Col>

            <Col size="w-full lg:w-1/2" className="order-last">
              <div className="mt-8 lg:-mr-12 lg:mt-0">
                <div className="relative pb-[64%]">
                  <video
                    width="100%"
                    height="300px"
                    preload="auto"
                    className="absolute w-full h-full top-0 bottom-0 left-0 right-0"
                    poster={poster}
                    loop
                    autoPlay
                    muted>
                    <source src={video} type="video/mp4" />
                    Your browser does not support HTML5 video.
                  </video>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <p className="mb-10 text-lg text-center text-gray-light font-normal opacity-75">
        {usersLogosTitle}
      </p>

      <Customers all/>
    </ShapeDown>
  )
}

export default HeroHome
