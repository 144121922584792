import * as React from "react"

const CardFeature = ({ icon, title, description }) => {
  return (
    <div className="flex flex-col w-full h-full p-12 bg-[#090c21] bg-opacity-50 rounded-xl">
      <img src={icon} alt={title} className="mb-6 mr-auto" />

      <h3 className="mb-6 text-white">{title}</h3>

      <p className="text-lg text-gray-light/80 font-medium">{description}</p>
    </div>
  )
}

export default CardFeature
