import * as React from "react"

import { Container } from "./ui/grid"
import CardIcon from "./ui/card-icon"

import Github from "../svg/stack/github.svg"
import Heroku from "../svg/stack/heroku.svg"
import Slack from "../svg/stack/slack.svg"
import Jenkins from "../svg/stack/jenkins.svg"
import Circleci from "../svg/stack/circleci.svg"
import Teamcity from "../svg/stack/teamcity.svg"
import Gitlab from "../svg/stack/gitlab.svg"
import TravisCi from "../svg/stack/travis-ci.svg"
import Java from "../svg/stack/java.svg"
import Azure from "../svg/stack/azure.svg"
import Node from "../svg/stack/node.svg"
import Kubernetes from "../svg/stack/kubernetes.svg"

const SupportStack = ({ elRef }) => {
  return (
    <section ref={elRef} className="my-12 md:mt-32 md:mb-36">
      <Container>
        <div className="max-w-4xl mx-auto text-center">
          <h2 className="mb-8 px-10 text-xl text-gray-dim lg:text-2xl">
            Supporting your technology stack
          </h2>

          <CardIcon logo={<Github className="w-full h-auto" />} />
          <CardIcon logo={<Heroku className="w-full h-auto" />} />
          <CardIcon logo={<Slack className="w-full h-auto" />} />
          <CardIcon logo={<Node className="w-full h-auto" />} />
          <CardIcon logo={<Java className="w-full h-auto" />} />
          <CardIcon logo={<Kubernetes className="w-full h-auto" />} />
          <CardIcon logo={<Jenkins className="w-full h-auto" />} />
          <CardIcon logo={<Circleci className="w-full h-auto" />} />
          <CardIcon logo={<Teamcity className="w-full h-auto" />} />
          <CardIcon logo={<Gitlab className="w-full h-auto" />} />
          <CardIcon logo={<TravisCi className="w-full h-auto" />} />
          <CardIcon logo={<Azure className="w-full h-auto" />} />
        </div>
      </Container>
    </section>
  )
}

export default SupportStack
