import * as React from "react"

const CardIcon = ({ logo }) => {
  return (
    <div className="inline-block p-4 mx-4 mb-4 h-20 w-20 border border-gray-200 bg-white rounded shadow-md md:h-28 md:w-28">
      {logo}
    </div>
  )
}

export default CardIcon
